<template>
    <v-flex d-flex flex-row class="gst-user-favorite-tab-events-filter">
        <v-flex class="gst-user-favorite-tab-events-filter__city d-flex align-center">
            <span class="d-inline-flex mr-1">
                <BaseIcon symbol-id="icons--location" class="gst-search-icon-svg" />
            </span>
            <CitySearchStoreDropDown
                v-model="filter.city"
                :data-test-id="$testId('cityFilter')"
                :only-favourite="true" />
        </v-flex>
    </v-flex>
</template>

<script>
    import CitySearchStoreDropDown from '@core/shared/components/input/CitySearchStoreDropDown';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'UserFavoriteTabsEventsFilter',
        components: {
            CitySearchStoreDropDown,
            BaseIcon
        },
        testIdOptions: {
            keyPrefix: 'userFavorites.eventsTab.filter'
        },
        props: {
            filter: {
                type: Object,
                default: null
            }
        }
    };
</script>